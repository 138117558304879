/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import { connect } from "react-redux";
// import OneSignal from "react-onesignal";
// Import Routes all
import { linkBioRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";
import { useHistory } from "react-router-dom";

// layouts Format
import VerticalLayout from "./components/VerticalLayout";
import BioLinks from "./pages/Links";
// import "./assets/scss/theme.scss";

const App = (props) => {
  const history = useHistory();
  function getLayout() {
    let layoutCls = VerticalLayout;

    switch (props.layout.layoutType) {
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  return (
    <React.Fragment>
      <Router>
        <Switch>
        {linkBioRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              exact 
            />
            ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
