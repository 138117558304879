import React from "react";

const MySMSComponent = ({ fill }) => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8062 1.37082L15.1341 8L21.8062 14.6292C21.9268 14.3771 22 14.0984 22 13.8008V2.19922C22 1.90157 21.9268 1.62292 21.8062 1.37082ZM20.0664 0.265625H1.93359C1.63595 0.265625 1.3573 0.338801 1.1052 0.459414L9.63291 8.94415C10.3869 9.69817 11.6131 9.69817 12.3671 8.94415L20.8948 0.459414C20.6427 0.338801 20.364 0.265625 20.0664 0.265625ZM0.193789 1.37082C0.0731758 1.62292 0 1.90157 0 2.19922V13.8008C0 14.0984 0.0731758 14.3771 0.193789 14.6292L6.86593 8L0.193789 1.37082Z"
        fill={fill}
      />
      <path
        d="M14.219 8.91113L13.2749 9.85528C12.0185 11.1116 9.97418 11.1116 8.71782 9.85528L7.77371 8.91113L1.10156 15.5403C1.35366 15.6609 1.63231 15.7341 1.92996 15.7341H20.0628C20.3604 15.7341 20.6391 15.6609 20.8912 15.5403L14.219 8.91113Z"
        fill={fill}
      />
    </svg>
  );
};

export default MySMSComponent;
