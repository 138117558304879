import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "reactstrap";
import "./links.css";
import ShareLogo from "../../assets/ShareLogo";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Share from "./Share";
import BioSkeleton from "./Skeleton/bioSkeleton";
import Pages404 from "./Page404";
import { NotificationToastContainer } from "../../components/Custom/notification";
import {
  getAllBioIcons,
  getBioLinkColorCode,
  getBioLinkTheme,
} from "../../services/linkBioService";
import Footer from "../../components/VerticalLayout/Footer";
import getThemeImage from "../../helpers/theme_helper";
import CustomHelmet from "../../components/CustomeHelmet";
import MyEXPORTComponent from "../../assets/MyEXPORTComponent";
import MySMSComponent from "../../assets/MySMSComponent";
import SubscribeModal from "./SubscribeModal";
import Vector from "../../assets/Vector";
import { getCookie } from "../../helpers/helper";
import { IconOptions } from "../../helpers/icon_helper";

const BioLinks = () => {
  const [subscribeData, setSubscribeData] = useState(null);
  const history = useHistory();
  const username = history.location.pathname.split("/")[1];
  const userQuery = history.location.search.split("?")[1];
  const [bioLinks, setBioLinks] = useState([]);
  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [share, setShare] = useState(false);
  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [style, setStyle] = useState(1);
  const [animationStyle, setAnimationStyle] = useState(1);
  const [themeCode, setThemeCode] = useState({});
  const [icons, setIcons] = useState([]);

  const biolinkColor = (id) => {
    getBioLinkColorCode(id).then((res) => {
      setStyle(res.data.data.data.color_code);
      setAnimationStyle(res.data.data.data.animation_code);
    });
  };
  const biolinkTheme = (id) => {
    getBioLinkTheme(id).then((res) => setThemeCode(res.data.data.data));
  };

  const bioLinkIcon = (id) => {
    getAllBioIcons(id).then((res) => setIcons(res.data.data.Icons));
  };

  useEffect(() => {
    if (getCookie(`subscribe_${profileData?.link_bio_id}`)) {
      setSubscribeData(
        JSON.parse(getCookie(`subscribe_${profileData?.link_bio_id}`))
      );
    }
  }, [open, profileData]);
  useEffect(() => {
    if (userQuery === "norecord=true") {
      setIsLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_BACK_HOST}/${username}?source=link-bio&${userQuery}`
        )
        .then((res) => {
          setIsError(false);
          const data = res?.data?.data;
          if (data?.message === "err_30") {
            window.location.href = data?.data;
          } else {
            if (data?.profileData) {
              setProfileData(data?.profileData);
              biolinkTheme(data?.profileData?.link_bio_id);
              biolinkColor(data?.profileData?.link_bio_id);
              bioLinkIcon(data?.profileData?.link_bio_id);
            }
            if (data?.bioLinkData) {
              setBioLinks(data?.bioLinkData);
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      axios
        .get(`${process.env.REACT_APP_BACK_HOST}/${username}?source=link-bio`)
        .then((res) => {
          setIsError(false);
          const data = res?.data?.data;
          if (data?.message === "err_30") {
            window.location.href = data?.data;
          } else {
            if (data?.profileData) {
              setProfileData(data?.profileData);
              biolinkTheme(data?.profileData?.link_bio_id);
              biolinkColor(data?.profileData?.link_bio_id);
              bioLinkIcon(data?.profileData?.link_bio_id);
            }
            if (data?.bioLinkData) {
              setBioLinks(data?.bioLinkData);
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsError(true);
          setIsLoading(false);
        });
    }
  }, [username]);

  return (
    <React.Fragment>
      <div
        className="main-content"
        style={{
          backgroundColor: "white",
          backgroundColor:
            themeCode && themeCode.theme_type === 0 ? themeCode.background : "",
          backgroundImage:
            themeCode && themeCode.theme_type === 1
              ? getThemeImage(themeCode.background_code)
              : "",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        {profileData ? <CustomHelmet profileData={profileData} /> : null}
        {isError ? (
          <Pages404 />
        ) : (
          <>
            {isLoading ? (
              <BioSkeleton />
            ) : (
              <>
                {profileData ? (
                  <div
                    style={{
                      marginBottom: "0px",
                      backgroundColor: "transparent",
                      padding: "16px 0",
                      height: "100vh",
                    }}
                  >
                    <div className="justify-content-center">
                      <div className="profile-effect">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{ position: "sticky" }}
                            className="link-animated"
                          >
                            <div className={`link-animated-${animationStyle}`}>
                              <div
                                className={` link-container-${style} share`}
                                style={{
                                  border:
                                    style > 2 && style < 7
                                      ? "1px solid #D7DEE4"
                                      : "",
                                  backgroundColor:
                                    themeCode?.button || "#EBEEF1",
                                  // background: themeCode && themeCode.background_code && themeCode.background_code === 2 ? "linear-gradient(90deg, #FF7DFF 0.12%, #7562FF 100%)" :"",
                                  color:
                                    themeCode?.background_code === 2
                                      ? "#000000"
                                      : themeCode?.button_text || "#000",
                                  boxShadow:
                                    (style === 7 ||
                                      style === 8 ||
                                      style === 9) &&
                                    `${
                                      themeCode?.button || "#FFFFFF"
                                    } 2px 1000px 1px inset`,
                                  cursor: "pointer",
                                }}
                                onClick={() => setShare(!share)}
                              >
                                {/* <ShareLogo
                              color={themeCode?.button_text || "#000000"}
                            /> */}
                                <MyEXPORTComponent
                                  fill={
                                    themeCode?.background_code === 2
                                      ? "#000000"
                                      : themeCode?.button_text || "#000"
                                  }
                                  className={`link-animated-text-${animationStyle}`}
                                />
                                <Share
                                  handleShare={() => setShare(!share)}
                                  share={share}
                                  data={profileData}
                                  url={`${process.env.REACT_APP_LINK_BIO_URL}/${profileData.username}`}
                                />
                              </div>
                            </div>
                          </div>
                          {!!profileData?.subscribe_status && (
                            <div
                              style={{ position: "sticky" }}
                              className="link-animated"
                            >
                              <div
                                className={`link-animated-${animationStyle}`}
                              >
                                <div
                                  className={`link-container-${style} subscribe`}
                                  style={{
                                    border:
                                      style > 2 && style < 7
                                        ? "1px solid #D7DEE4"
                                        : "",
                                    backgroundColor:
                                      themeCode?.button || "#EBEEF1",
                                    // background: themeCode && themeCode.background_code && themeCode.background_code === 2 ? "linear-gradient(90deg, #FF7DFF 0.12%, #7562FF 100%)" :"",
                                    color:
                                      themeCode?.background_code === 2
                                        ? "#000000"
                                        : themeCode?.button_text || "#000",
                                    boxShadow:
                                      (style === 7 ||
                                        style === 8 ||
                                        style === 9) &&
                                      `${
                                        themeCode?.button || "#FFFFFF"
                                      } 2px 1000px 1px inset`,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setOpen(!open)}
                                >
                                  {subscribeData?.link_bio_id ===
                                  profileData?.link_bio_id ? (
                                    <>
                                      <Vector
                                        fill={
                                          themeCode?.background_code === 2
                                            ? "#000000"
                                            : themeCode?.button_text || "#000"
                                        }
                                      />
                                      <span
                                        style={{ marginLeft: "10px" }}
                                        className={`link-animated-text-${animationStyle}`}
                                      >
                                        Subscribed
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <MySMSComponent
                                        fill={
                                          themeCode?.background_code === 2
                                            ? "#000000"
                                            : themeCode?.button_text || "#000"
                                        }
                                      />
                                      <span
                                        style={{ marginLeft: "10px" }}
                                        className={`link-animated-text-${animationStyle}`}
                                      >
                                        Subscribe
                                      </span>
                                    </>
                                  )}
                                  <SubscribeModal
                                    username={username}
                                    open={open}
                                    setOpen={setOpen}
                                    profileData={profileData}
                                    handleClose={() => setOpen(!open)}
                                    setSubscribeData={setSubscribeData}
                                    hasCookies={
                                      subscribeData?.link_bio_id ===
                                      profileData?.link_bio_id
                                        ? subscribeData
                                        : null
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className="d-flex flex-column align-items-center"
                          style={{ height: "145px", marginTop: "20px" }}
                        >
                          {!profileData?.profile_image?.length ? (
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                height: "5.0rem",
                                width: "5.0rem",
                                borderRadius: "50%",
                                fontSize: "2.5rem",
                                color: "white",
                                background: "black",
                                boxShadow: "0px 0px 4px rgb(4, 33, 43)",
                              }}
                            >
                              {username.charAt(0).toUpperCase()}
                            </div>
                          ) : (
                            <img
                              className="rounded-circle shadow-1-strong"
                              src={profileData.profile_image}
                              alt="profile"
                              style={{
                                height: "5.0rem",
                                width: "5.0rem",
                                borderRadius: "50%",
                              }}
                            />
                          )}
                          <h1
                            style={{
                              color: themeCode?.title_text || "#000000",
                            }}
                            className="profile-title"
                          >
                            {profileData?.bio_title}
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        !profileData.branding_toggle
                          ? "links-wrapper"
                          : "links-wrapper-logo"
                      }
                      style={{
                        overflowY: "scroll",
                        overflowX: "auto",
                      }}
                    >
                      <div
                        style={{
                          padding: "8px",
                          color: themeCode?.title_text || "#000000",
                          overflowWrap: "break-word",
                        }}
                      >
                        <h2
                          className="text-center profile-description"
                          style={{
                            color: themeCode?.title_text || "#000000",
                          }}
                        >
                          {profileData.bio_description}
                        </h2>
                      </div>
                      {profileData?.social_icon_position === 0 && (
                        <div
                          style={{
                            padding: "0px 8px 8px 8px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            width: "100%",
                            overflowWrap: "break-word",
                          }}
                        >
                          {icons?.map((item) => {
                            return (
                              <a
                                target="_blank"
                                rel="noopener"
                                title={
                                  item.is_custom
                                    ? item.username
                                    : IconOptions.find(
                                        (icon) => icon.no == item?.icon
                                      )?.name
                                }
                                data-testid="SocialIcon"
                              >
                                <img
                                  loading="lazy"
                                  width="28"
                                  src={
                                    item.is_custom
                                      ? item.icon_image
                                      : IconOptions.find(
                                          (icon) => icon.no == item?.icon
                                        )?.icon
                                  }
                                  alt=""
                                  style={{
                                    marginLeft: "8px",
                                    marginRight: "8px",
                                    marginTop: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    window.open(item?.short_url);
                                  }}
                                />
                              </a>
                            );
                          })}
                        </div>
                      )}
                      {bioLinks.map((ele, index) => {
                        return (
                          <a
                            href={`${ele.short_url}`}
                            rel="noreferrer"
                            target="_blank"
                            key={index}
                            style={{
                              position: "relative",
                              zIndex: "1",
                              color: "#000",
                              margin: "0px 20px",
                            }}
                            md={12}
                            className="link-animated"
                          >
                            <div className={`link-animated-${animationStyle}`}>
                              <div
                                className={`link-container-${style}`}
                                style={{
                                  border:
                                    style > 2 && style < 7
                                      ? "1px solid #D7DEE4"
                                      : "",
                                  backgroundColor:
                                    themeCode?.button || "#EBEEF1",
                                  // background: themeCode && themeCode.background_code && themeCode.background_code === 2 ? "linear-gradient(90deg, #FF7DFF 0.12%, #7562FF 100%)" :"",
                                  color:
                                    themeCode?.background_code === 2
                                      ? "#000000"
                                      : themeCode?.button_text || "#000",
                                  boxShadow:
                                    (style === 7 ||
                                      style === 8 ||
                                      style === 9) &&
                                    `${
                                      themeCode?.button || "#FFFFFF"
                                    } 2px 1000px 1px inset`,
                                }}
                              >
                                <div
                                  style={{
                                    minWidth: 48,
                                    height: 48,
                                    maxWidth: 48,
                                  }}
                                  className="d-flex"
                                >
                                  {ele.thumbnail && (
                                    <img
                                      className={`rounded-circle shadow-1-strong align-items-center link-animated-text-${animationStyle}`}
                                      src={ele.thumbnail}
                                      style={{
                                        margin: "auto",
                                        marginLeft: "8px",
                                      }}
                                      alt=""
                                      height="90%"
                                      width="90%"
                                      borderRadius="100%"
                                    />
                                  )}
                                </div>
                                <div
                                  className="m-auto"
                                  style={{ paddingLeft: "8px" }}
                                >
                                  <h2
                                    style={{
                                      color:
                                        themeCode?.button_text || "#000000",
                                    }}
                                    className={`bioLinks-link-title link-animated-text-${animationStyle}`}
                                  >
                                    {ele.link_title}
                                  </h2>
                                </div>
                                <div
                                  style={{
                                    minWidth: 48,
                                    height: 48,
                                    maxWidth: 48,
                                  }}
                                  className="d-flex"
                                ></div>
                              </div>
                            </div>
                          </a>
                        );
                      })}
                      {profileData?.social_icon_position === 1 && (
                        <div
                          style={{
                            padding: "0px 8px 8px 8px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexWrap: "wrap",
                            width: "100%",
                            overflowWrap: "break-word",
                          }}
                        >
                          {icons?.map((item) => {
                            return (
                              <a
                                target="_blank"
                                rel="noopener"
                                title={
                                  item.is_custom
                                    ? item.username
                                    : IconOptions.find(
                                        (icon) => icon.no == item?.icon
                                      )?.name
                                }
                                data-testid="SocialIcon"
                              >
                                <img
                                  loading="lazy"
                                  width="28"
                                  src={
                                    item.is_custom
                                      ? item.icon_image
                                      : IconOptions.find(
                                          (icon) => icon.no == item.icon
                                        )?.icon
                                  }
                                  alt=""
                                  style={{
                                    marginLeft: "8px",
                                    marginRight: "8px",
                                    marginTop: "8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => window.open(item?.short_url)}
                                />
                              </a>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    {!profileData.branding_toggle && (
                      <Footer profileData={profileData} />
                    )}
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        )}
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
};

export default BioLinks;
