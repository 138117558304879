export const DevURL = `${process.env.REACT_APP_BACKEND_HOST}/api/user`;
// export const DevURL = "http://localhost:7000/api/user/";

export const GET_USER = "/linkBio/user";
export const GET_URLS = "/linkBio/urls";
export const PREVIEW_URL = "/linkBio/preview";
export const GET_ICON = "/link_bio_icon/get_icons";
export const COLOR_CODE = "/color";
export const BIOLINK_THEME = "/color/theme";
export const ADD_SUBSCRIBE_USER = "/subscribe_user";
export const VERIFY_SUBSCRIBE_OTP = "/verify_subscribe_otp";
export const UNSUBSCRIBE_USER = "/unsubscribe_user";

export const URL = DevURL;
