import api from "../api/api";
import * as url from "../api/url";

export const getUser = (params) => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.GET_USER + params)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllBioUrls = (params) => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.GET_URLS + params)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getAllBioIcons = (params) => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.GET_ICON + `?link_bio_id=${params}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const redirectLink = (params) => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.PREVIEW_URL + params)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getBioLinkTheme = (params) => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.BIOLINK_THEME + `?link_bio_id=${params}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getBioLinkColorCode = (params) => {
  return new Promise((resolve, reject) => {
    return api
      .get(url.COLOR_CODE + `?link_bio_id=${params}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const addSubscribeUser = ({ user_id, link_bio_id, payload }) => {
  return new Promise((resolve, reject) => {
    return api
      .post(
        url.ADD_SUBSCRIBE_USER +
          `?user_id=${user_id}` +
          `&link_bio_id=${link_bio_id}`,
        payload
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const verifySubscribeOtp = (payload, link_bio_id) => {
  return new Promise((resolve, reject) => {
    return api
      .post(`${url.VERIFY_SUBSCRIBE_OTP}?link_bio_id=${link_bio_id}`, payload)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const unSubscribe = ({ subscribe_email, link_bio_id }) => {
  return new Promise((resolve, reject) => {
    return api
      .put(`${url.UNSUBSCRIBE_USER}?link_bio_id=${link_bio_id}`, {
        subscribe_email: subscribe_email,
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
