import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import copy from "copy-to-clipboard";
import { notifySuccess } from "../../components/Custom/notification";
import foxLogo from "../../assets/Foxly_logo1.png";
import { Icon } from "@iconify/react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TumblrShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  PinterestIcon,
  TumblrIcon,
  WhatsappIcon,
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
  EmailShareButton,
} from "react-share";
import "./links.css";
import QRCodeStyling from "qr-code-styling";

const qrCode = new QRCodeStyling({
  width: 200,
  height: 210,
  margin: 0,
  dotsOptions: {
    color: "#000000",
    type: "square",
  },
  imageOptions: {
    hideBackgroundDots: true,
    imageSize: 0.5,
    margin: 0,
  },
});

const Share = ({ share, handleShare, url, data }) => {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);

  const copyToClipboard = () => {
    copy(url);
    notifySuccess("Link copied successfully");
  };

  const handleQrCodeRef = (element) => {
    if (element) {
      setLoading(true);
      const updatedRef = element;
      ref.current = updatedRef;
      qrCode.append(ref.current);
      setLoading(false);
    }
  };

  useEffect(() => {
    const updateQRCode = async () => {
      setLoading(true);

      if (!url) {
        setLoading(true);
      } else {
        qrCode.update({
          data: url,
        });

        await new Promise((resolve) => setTimeout(resolve, 1000));

        qrCode.append(ref.current);

        setLoading(false);
      }
    };

    updateQRCode();
  }, [data, url]);

  const handlePNGQRCodeDownload = () => {
    qrCode.download({
      name: "download",
      extension: "png",
    });
  };

  const handleSVGQRCodeDownload = () => {
    qrCode.download({
      name: "download",
      extension: "svg",
    });
  };

  return (
    <>
      <Modal isOpen={share} centered={true}>
        <ModalHeader toggle={handleShare}>Share Your Link-Bio</ModalHeader>
        <ModalBody className="share-modalBody">
          <div>
            <InputGroup style={{ overflowX: "scroll", flexWrap: "nowrap" }}>
              <InputGroupText
                style={{
                  border: "1px solid #ced4da",
                  backgroundColor: "transparent",
                }}
              >
                <i className="cursor-pointer mdi mdi-link-variant font-size-16"></i>
              </InputGroupText>
              <Input
                value={url}
                style={{
                  borderLeft: "none",
                  minWidth: "maxContent",
                  paddingLeft: "0px",
                }}
              />
              <Button
                color="primary"
                style={{
                  backgroundColor: "#4680FF",
                  border: "1px solid #4680FF",
                }}
                onClick={() => copyToClipboard()}
              >
                Copy
              </Button>
            </InputGroup>
            <div className="text-center"></div>
          </div>
          <div>
            <div
              style={{
                textAlign: "center",
                margin: "24px 0px",
                padding: "16px",
                boxShadow: "0px 0px 15px 1px rgba(0,0,0,0.2) ",
                borderRadius: "8px",
              }}
            >
              {loading ? (
                <Spinner color="primary"></Spinner>
              ) : (
                <>
                  <div>
                    <div ref={handleQrCodeRef}></div>
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    <Button
                      outline
                      className="btnDownload me-2"
                      onClick={handlePNGQRCodeDownload}
                    >
                      <i class="cursor-pointer mdi mdi-download font-size-18"></i>{" "}
                      PNG
                    </Button>
                    <Button
                      outline
                      className="btnDownload"
                      onClick={handleSVGQRCodeDownload}
                    >
                      <i class="cursor-pointer mdi mdi-download font-size-18"></i>{" "}
                      SVG
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center mt-2">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <EmailShareButton
                url={url}
                hashtag={""}
                style={{ margin: "2.5px 2.5px" }}
              >
                <EmailIcon
                  url={url.short_url}
                  hashtag=""
                  size={45}
                  iconFillColor={"#F75590"}
                  round
                  bgStyle={{
                    fill: "#ffffff",
                  }}
                  style={{
                    borderRadius: "50%",
                    display: "flex",
                    border: "1px solid gray",
                  }}
                />
              </EmailShareButton>

              <FacebookShareButton
                url={url}
                hashtag={""}
                style={{ margin: "2.5px 2.5px" }}
              >
                <FacebookIcon
                  size={45}
                  iconFillColor={"#1877F2"}
                  round
                  bgStyle={{
                    fill: "#ffffff",
                  }}
                  style={{
                    borderRadius: "50%",
                    display: "flex",
                    border: "1px solid gray",
                  }}
                />
              </FacebookShareButton>

              <TwitterShareButton
                url={url}
                hashtag={""}
                style={{ margin: "2.5px 2.5px" }}
              >
                <Icon
                  icon="pajamas:twitter"
                  height={45}
                  width={45}
                  style={{
                    cursor: "pointer",
                    borderRadius: "50%",
                    display: "flex",
                    border: "1px solid gray",
                    padding: "10px",
                    color: "black",
                  }}
                />
              </TwitterShareButton>

              <LinkedinShareButton
                url={url}
                hashtag={""}
                style={{ margin: "2.5px 2.5px" }}
              >
                <LinkedinIcon
                  size={45}
                  iconFillColor={"#007AB9"}
                  round
                  bgStyle={{
                    fill: "#ffffff",
                  }}
                  style={{
                    borderRadius: "50%",
                    display: "flex",
                    border: "1px solid gray",
                  }}
                />
              </LinkedinShareButton>

              <PinterestShareButton
                url={url}
                media={url}
                hashtag={""}
                style={{ margin: "2.5px 2.5px" }}
              >
                <PinterestIcon
                  size={45}
                  iconFillColor={"#D50012"}
                  round
                  bgStyle={{
                    fill: "#ffffff",
                  }}
                  style={{
                    borderRadius: "50%",
                    display: "flex",
                    border: "1px solid gray",
                  }}
                />
              </PinterestShareButton>

              <TumblrShareButton
                url={url}
                hashtag={""}
                style={{ margin: "2.5px 2.5px" }}
              >
                <TumblrIcon
                  size={45}
                  iconFillColor={"#34526F"}
                  round
                  bgStyle={{
                    fill: "#ffffff",
                  }}
                  style={{
                    borderRadius: "50%",
                    display: "flex",
                    border: "1px solid gray",
                  }}
                />
              </TumblrShareButton>

              <WhatsappShareButton
                url={url}
                hashtag={""}
                style={{ margin: "2.5px 2.5px" }}
              >
                <WhatsappIcon
                  size={45}
                  iconFillColor={"#29A71A"}
                  round
                  bgStyle={{
                    fill: "#ffffff",
                  }}
                  style={{
                    borderRadius: "50%",
                    display: "flex",
                    border: "1px solid gray",
                  }}
                />
              </WhatsappShareButton>

              <TelegramShareButton
                url={url}
                hashtag={""}
                style={{ margin: "2.5px 2.5px" }}
              >
                <TelegramIcon
                  size={45}
                  iconFillColor={"#039BE5"}
                  round
                  bgStyle={{
                    fill: "#ffffff",
                  }}
                  style={{
                    borderRadius: "50%",
                    display: "flex",
                    border: "1px solid gray",
                  }}
                />
              </TelegramShareButton>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Share;
