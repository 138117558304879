import React from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import FoxlyLogo from "../../assets/images/foxly-logo2.png";
import errorImage from "../../assets/images/error-img.png";

const Pages404 = () => {
  return (
    <React.Fragment>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "542px",
            // height: "789px",
            boxShadow: "0px 8px 24px 0px rgba(27,46,94,0.3)",
            borderRadius: "16px",
          }}
        >
          <div className="text-center p-5">
            <div className="img">
              <a
                href={`${process.env.REACT_APP_FRONTEND_HOST}`}
                target="_blank"
                rel="noreferrer"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={FoxlyLogo}
                  style={{ width: "90px" }}
                  className="img-fluid mb-3"
                  alt=""
                />
              </a>
            </div>
            <div className="img">
              <img src={errorImage} className="img-fluid" alt="" />
            </div>

            <h1
              className=" mt-4"
              style={{
                fontSize: "20px",
                fontWeight: 500,
                color: "#1D2630",
                lineHeight: "28px",
              }}
            >
              Link-bio profile not found.
            </h1>
            <h4
              className="mb-3 mt-3"
              style={{
                fontSize: "16px",
                fontWeight: 400,
                color: "#5B6B79",
                lineHeight: "24px",
              }}
            >
              Check your link-bio profile url.
            </h4>
            {/* <p className="mb-4 w-75 mx-auto">
                      It will be as simple as Occidental in fact, it will
                      Occidental to an English person
                    </p> */}
            <a
              className="btn btn-primary  waves-effect waves-light"
              href="/"
              style={{
                backgroundColor: "#4680FF",
                padding: "15px 20px",
                width: "100%",
                border: "none",
              }}
            >
              Back to Foxly
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Pages404;
