import Threads from "../assets/images/Link-bio-icon/Threads.png";
import Instgram from "../assets/images/Link-bio-icon/Instagram.png";
import Email from "../assets/images/Link-bio-icon/Email.png";
import Facebook from "../assets/images/Link-bio-icon/Facebook.png";
import Youtube from "../assets/images/Link-bio-icon/Youtube.png";
import Twitter from "../assets/images/Link-bio-icon/Twitter.png";
import TikTok from "../assets/images/Link-bio-icon/TikTok.png";
import Whatsapp from "../assets/images/Link-bio-icon/Whatsapp.png";
import Snapchat from "../assets/images/Link-bio-icon/Snapchat.png";
import Airchat from "../assets/images/Link-bio-icon/Airchat.png";
import Amazon from "../assets/images/Link-bio-icon/Amazon.png";
import AndroidPlayStore from "../assets/images/Link-bio-icon/AndroidPlayStore.png";
import AppleAppStore from "../assets/images/Link-bio-icon/AppleAppStore.png";
import AppleMusic from "../assets/images/Link-bio-icon/AppleMusic.png";
import ApplePodcast from "../assets/images/Link-bio-icon/ApplePodcast.png";
import Bandcamp from "../assets/images/Link-bio-icon/Bandcamp.png";
import BeReal from "../assets/images/Link-bio-icon/BeReal.png";
import Bluesky from "../assets/images/Link-bio-icon/Bluesky.png";
import Cameo from "../assets/images/Link-bio-icon/Cameo.png";
import Clubhouse from "../assets/images/Link-bio-icon/Clubhouse.png";
import Discord from "../assets/images/Link-bio-icon/Discord.png";
import Etsy from "../assets/images/Link-bio-icon/Etsy.png";
import Github from "../assets/images/Link-bio-icon/Github.png";
import Kick from "../assets/images/Link-bio-icon/Kick.png";
import Linkedin from "../assets/images/Link-bio-icon/Linkedin.png";
import Mastodon from "../assets/images/Link-bio-icon/Mastodon.png";
import Patreon from "../assets/images/Link-bio-icon/Patreon.png";
import Payment from "../assets/images/Link-bio-icon/Payment.png";
import Pinterest from "../assets/images/Link-bio-icon/Pinterest.png";
import Poshmark from "../assets/images/Link-bio-icon/Poshmark.png";
import Signal from "../assets/images/Link-bio-icon/Signal.png";
import Soundcloud from "../assets/images/Link-bio-icon/Soundcloud.png";
import Spotify from "../assets/images/Link-bio-icon/Spotify.png";
import Substack from "../assets/images/Link-bio-icon/Substack.png";
import Telegram from "../assets/images/Link-bio-icon/Telegram.png";
import Twitch from "../assets/images/Link-bio-icon/Twitch.png";

export const IconOptions = [
  { name: "Instgram", icon: Instgram, no: 1 },
  { name: "Email", icon: Email, no: 2 },
  { name: "Facebook", icon: Facebook, no: 3 },
  { name: "Youtube", icon: Youtube, no: 4 },
  { name: "X (formerly Twitter)", icon: Twitter, no: 5 },
  { name: "TikTok", icon: TikTok, no: 6 },
  { name: "Whatsapp", icon: Whatsapp, no: 7 },
  { name: "Snapchat", icon: Snapchat, no: 8 },
  { name: "Airchat", icon: Airchat, no: 9 },
  { name: "Amazon", icon: Amazon, no: 10 },
  { name: "Android Play Store", icon: AndroidPlayStore, no: 11 },
  { name: "Apple App Store", icon: AppleAppStore, no: 12 },
  { name: "Apple Music", icon: AppleMusic, no: 13 },
  { name: "Apple Podcast", icon: ApplePodcast, no: 14 },
  { name: "Bandcamp", icon: Bandcamp, no: 15 },
  { name: "BeReal", icon: BeReal, no: 16 },
  { name: "Bluesky", icon: Bluesky, no: 17 },
  { name: "Cameo", icon: Cameo, no: 18 },
  { name: "Clubhouse", icon: Clubhouse, no: 19 },
  { name: "Discord", icon: Discord, no: 20 },
  { name: "Etsy", icon: Etsy, no: 21 },
  { name: "Github", icon: Github, no: 22 },
  { name: "Kick", icon: Kick, no: 23 },
  { name: "Linkedin", icon: Linkedin, no: 24 },
  { name: "Mastodon", icon: Mastodon, no: 25 },
  { name: "Patreon", icon: Patreon, no: 26 },
  { name: "Payment", icon: Payment, no: 27 },
  { name: "Pinterest", icon: Pinterest, no: 28 },
  { name: "Poshmark", icon: Poshmark, no: 29 },
  { name: "Signal", icon: Signal, no: 30 },
  { name: "Soundcloud", icon: Soundcloud, no: 31 },
  { name: "Spotify", icon: Spotify, no: 32 },
  { name: "Substack", icon: Substack, no: 33 },
  { name: "Telegram", icon: Telegram, no: 34 },
  { name: "Twitch", icon: Twitch, no: 35 },
  { name: "Threads", icon: Threads, no: 36 },
];
