import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

const CustomHelmet = ({ profileData }) => {
  useEffect(() => {
    if (profileData) {
      updateMetaTags();
    }
  }, [profileData]);

  const updateMetaTags = () => {
    const metaTags = [
      { name: "description", content: profileData.meta_description },
    ];

    const linkTags = [
      { rel: "icon", type: "image/png", href: profileData.meta_image },
    ];

    if (!profileData.meta_image) {
      linkTags.push({ rel: "icon", href: "/favicon.png" });
    }

    metaTags.forEach((tag) => {
      const existingTag = document.querySelector(`meta[name="${tag.name}"]`);
      if (existingTag) {
        existingTag.setAttribute("content", tag.content);
      } else {
        const newTag = document.createElement("meta");
        newTag.setAttribute("name", tag.name);
        newTag.setAttribute("content", tag.content);
        document.head.appendChild(newTag);
      }
    });

    linkTags.forEach((tag) => {
      const existingTag = document.querySelector(
        `link[rel="${tag.rel}"][type="${tag.type}"]`
      );
      if (existingTag) {
        existingTag.setAttribute("href", tag.href);
      } else {
        const newTag = document.createElement("link");
        newTag.setAttribute("rel", tag.rel);
        if (tag.type) {
          newTag.setAttribute("type", tag.type);
        }
        newTag.setAttribute("href", tag.href);
        document.head.appendChild(newTag);
      }
    });
  };

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{profileData.meta_title}</title>
    </Helmet>
  );
};

export default CustomHelmet;
