import React from "react";
import foxLogo from "../../assets/images/foxly-logo.png";

const Footer = () => {
  return (
    <React.Fragment>
      <footer>
        <div className="d-flex justify-content-center py-3">
          <a
            href={`${process.env.REACT_APP_WEB_SITE}`}
            target="_blank"
            rel="noreferrer"
            style={{ cursor: "pointer" }}
          >
            <img
              src={foxLogo}
              alt=""
              height="50px"
              width="100px"
              className="footer-logo"
              style={{ height: "auto" }}
            />
          </a>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
