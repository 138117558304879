import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../links.css";

function BioSkeleton() {
  return (
    <div>
      <div
        style={{
          marginBottom: "0px",
          backgroundColor: "transparent",
          padding: "16px 0px",
          height: "100vh",
        }}
      >
        <div className="d-flex flex-column align-items-center">
          <div>
            <Skeleton height="5.5rem" width="5.5rem" borderRadius="50%" />
            <Skeleton className="mt-3" />
          </div>
          <div className="links mt-4" style={{ width: 350 }}>
            <div>
              <Skeleton />
            </div>
          </div>
          <div className="links" style={{ width: 350 }}>
            <div>
              <Skeleton />
            </div>
          </div>
          <div className="links" style={{ width: 350 }}>
            <div>
              <Skeleton />
            </div>
          </div>
          <div className="links" style={{ width: 350 }}>
            <div>
              <Skeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BioSkeleton;
