import React from "react";

const MyEXPORTComponent = ({ fill }) => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.95925 5.02016C2.89094 4.9492 2.83728 4.86548 2.80133 4.77378C2.76538 4.68208 2.74785 4.5842 2.74973 4.48572C2.75161 4.38724 2.77287 4.2901 2.81229 4.19984C2.85172 4.10958 2.90853 4.02797 2.9795 3.95966L6.41975 0.649164C7.2485 -0.180336 8.741 -0.190836 9.5915 0.659664L13.0205 3.95966C13.0915 4.02797 13.1484 4.10959 13.1878 4.19987C13.2273 4.29015 13.2486 4.38732 13.2505 4.48583C13.2524 4.58434 13.2349 4.68227 13.199 4.77401C13.1631 4.86576 13.1094 4.94953 13.0411 5.02054C12.9728 5.09155 12.8912 5.14841 12.8009 5.18788C12.7106 5.22735 12.6135 5.24865 12.515 5.25057C12.4164 5.25248 12.3185 5.23497 12.2268 5.19905C12.135 5.16312 12.0513 5.10947 11.9802 5.04116L8.75 1.93166V12.0004C8.75 12.1993 8.67098 12.3901 8.53033 12.5307C8.38968 12.6714 8.19891 12.7504 8 12.7504C7.80109 12.7504 7.61032 12.6714 7.46967 12.5307C7.32902 12.3901 7.25 12.1993 7.25 12.0004V1.93166L4.01975 5.04116C3.94879 5.10947 3.86507 5.16313 3.77337 5.19908C3.68167 5.23503 3.58378 5.25257 3.48531 5.25069C3.38683 5.24881 3.28969 5.22755 3.19942 5.18812C3.10916 5.1487 3.02755 5.09113 2.95925 5.02016ZM14.75 6.00041C14.5511 6.00041 14.3603 6.07943 14.2197 6.22008C14.079 6.36074 14 6.5515 14 6.75041V14.2504C14 15.4909 12.9905 16.5004 11.75 16.5004H4.25C3.0095 16.5004 2 15.4909 2 14.2504V6.75041C2 6.5515 1.92098 6.36074 1.78033 6.22008C1.63968 6.07943 1.44891 6.00041 1.25 6.00041C1.05109 6.00041 0.860322 6.07943 0.71967 6.22008C0.579018 6.36074 0.5 6.5515 0.5 6.75041V14.2504C0.5 16.3182 2.18225 18.0004 4.25 18.0004H11.75C13.8177 18.0004 15.5 16.3182 15.5 14.2504V6.75041C15.5 6.5515 15.421 6.36074 15.2803 6.22008C15.1397 6.07943 14.9489 6.00041 14.75 6.00041Z"
        fill={fill}
      />
    </svg>
  );
};

export default MyEXPORTComponent;
