import image1 from "../assets/images/theme/image1.svg";
import image2 from "../assets/images/theme/image2.svg";
import image3 from "../assets/images/theme/image3.svg";
import image4 from "../assets/images/theme/image4.svg";
import image5 from "../assets/images/theme/image5.svg";
import image6 from "../assets/images/theme/image6.svg";
import image7 from "../assets/images/theme/image7.svg";
import image8 from "../assets/images/theme/image8.svg";
import image9 from "../assets/images/theme/image9.svg";
import image10 from "../assets/images/theme/image10.svg";
import image11 from "../assets/images/theme/image11.svg";
import image12 from "../assets/images/theme/image12.svg";
import image13 from "../assets/images/theme/image13.svg";
import image14 from "../assets/images/theme/image14.svg";
import image15 from "../assets/images/theme/image15.svg";
import image16 from "../assets/images/theme/image16.svg";
import image17 from "../assets/images/theme/image17.svg";
import image18 from "../assets/images/theme/image18.svg";
import image19 from "../assets/images/theme/image19.svg";
import image20 from "../assets/images/theme/image20.svg";
import image21 from "../assets/images/theme/image21.svg";
import image22 from "../assets/images/theme/image22.svg";
import image23 from "../assets/images/theme/image23.svg";
import image24 from "../assets/images/theme/image24.svg";
import image25 from "../assets/images/theme/image25.svg";
// import "./theme_helper.css";

const getThemeImage = (data) => {
  // , width, height
  let imageUrl = "";
  switch (data) {
    case 1:
      imageUrl = `url(${image1})`;
      break;
    case 2:
      imageUrl = `url(${image2})`;
      break;
    case 3:
      imageUrl = `url(${image3})`;
      break;
    case 4:
      imageUrl = `url(${image4})`;
      break;
    case 5:
      imageUrl = `url(${image5})`;
      break;
    case 6:
      imageUrl = `url(${image6})`;
      break;
    case 7:
      imageUrl = `url(${image7})`;
      break;
    case 8:
      imageUrl = `url(${image8})`;
      break;
    case 9:
      imageUrl = `url(${image9})`;
      break;
    case 10:
      imageUrl = `url(${image10})`;
      break;
    case 11:
      imageUrl = `url(${image11})`;
      break;
    case 12:
      imageUrl = `url(${image12})`;
      break;
    case 13:
      imageUrl = `url(${image13})`;
      break;
    case 14:
      imageUrl = `url(${image14})`;
      break;
    case 15:
      imageUrl = `url(${image15})`;
      break;
    case 16:
      imageUrl = `url(${image16})`;
      break;
    case 17:
      imageUrl = `url(${image17})`;
      break;
    case 18:
      imageUrl = `url(${image18})`;
      break;
    case 19:
      imageUrl = `url(${image19})`;
      break;
    case 20:
      imageUrl = `url(${image20})`;
      break;
    case 21:
      imageUrl = `url(${image21})`;
      break;
    case 22:
      imageUrl = `url(${image22})`;
      break;
    case 23:
      imageUrl = `url(${image23})`;
      break;
    case 24:
      imageUrl = `url(${image24})`;
      break;
    case 25:
      imageUrl = `url(${image25})`;
      break;
    default:
      imageUrl = "";
      break;
  }
  // imageUrl += `; width: 100px; height: 100px;`;
  // if (width && height) {
  //   imageUrl += `; width: ${width}px; height: ${height}px;`;
  // }
  return imageUrl;
};

export default getThemeImage;
