import React from "react";
import { Redirect } from "react-router-dom";
import BioLinks from "../pages/Links/index";

const linkBioRoutes = [
  {
    path: "/",
    exact: true,
    component: () => {
      window.location = process.env.REACT_APP_WEB_SITE;
    },
  },
  { path: "/:bioData", component: BioLinks },
];

export { linkBioRoutes };
