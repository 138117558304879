import React, { useEffect, useState } from "react";
import { Button, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import copy from "copy-to-clipboard";
import {
  notifyError,
  notifySuccess,
} from "../../components/Custom/notification";
import SubscribeProfileIcon from "../../assets/SubscribeProfileIcon.png";
import VerifyOTPProfileIcon from "../../assets/VerifyOTPProfileIcon.png";
import validateEmail from "../../helpers/validateEmail";
import {
  addSubscribeUser,
  unSubscribe,
  verifySubscribeOtp,
} from "../../services/linkBioService";
import { notificationMessageFunction } from "../../constants/notificationConst";
import { deleteCookie, setCookie } from "../../helpers/helper";

const SubscribeModal = ({
  open,
  setOpen,
  profileData,
  handleClose,
  username,
  hasCookies,
  setSubscribeData,
}) => {
  const [valueData, setValue] = useState({ email: "", verify_otp: null });
  const [isValid, setIsValid] = useState(false);
  const [laval, setLaval] = useState(0);
  const [loading, setLoading] = useState(false);
  const context = [
    {
      title: "Subscribe",
      label: (
        <span>
          Receive the latest updates from <br />
          {profileData?.bio_title || profileData?.username} straight to your
          email inbox.
        </span>
      ),
      icon: SubscribeProfileIcon,
    },
    {
      title: "Verify Your Email",
      label: (
        <span>
          Please enter the verification code we <br /> {valueData?.email} sent
          to
        </span>
      ),
      icon: VerifyOTPProfileIcon,
    },
    {
      title: profileData?.message_subject,
      label: profileData?.message_description,
      icon: profileData?.profile_image,
    },
    {
      title: "Unsubscribe?",
      label: (
        <span>
          You’ll no longer receive emails <br /> from{" "}
          {profileData?.bio_title || profileData?.username}.
        </span>
      ),
      icon: profileData?.profile_image,
    },
  ];

  useEffect(() => {
    if (!!hasCookies) {
      setLaval(3);
    }
  }, [hasCookies]);

  const sendOtp = () => {
    addSubscribeUser({
      user_id: profileData?.user_id,
      link_bio_id: profileData?.link_bio_id,
      payload: { subscribe_email: valueData?.email },
    })
      .then((res) => {
        setLaval(1);
        setLoading(false);
        setIsValid(false);
        notifySuccess(notificationMessageFunction(res?.data?.data?.message));
      })
      .catch((err) => {
        notifyError(notificationMessageFunction(err?.data?.error?.message));
        setLaval(0);
        setLoading(false);
        setIsValid(false);
      });
  };

  return (
    <>
      <Modal
        isOpen={open}
        centered={true}
        style={{
          maxWidth: laval === 3 ? "380px" : "500px",
        }}
        wrapClassName={`subscribe-modal-wrapper-${laval}`}
        onClosed={() => {
          setValue({ email: "", verify_otp: null });
          if (!hasCookies) {
            setLaval(0);
          }
        }}
      >
        <ModalHeader
          toggle={handleClose}
          style={{ borderBottom: "none", paddingBottom: "0px" }}
        ></ModalHeader>
        <ModalBody style={{}}>
          <div
            style={{
              borderRadius: "50%",
              width: "98px",
              height: "98px",
              position: "absolute",
              backgroundColor: "white",
              top: "-40px",
              left: "calc(50% - 48px) ",
              boxShadow: "0px 8px 48px 0px #1D263029",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {context[laval]?.icon?.length > 0 ? (
              <img
                src={context[laval]?.icon}
                width={laval > 1 ? "90px" : "inherit"}
                height={laval > 1 ? "90px" : "inherit"}
                style={{
                  overflow: laval > 1 ? "hidden" : "inherit",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: "90px",
                  width: "90px",
                  borderRadius: "50%",
                  fontSize: "2.5rem",
                  color: "white",
                  background: "black",
                }}
              >
                {username.charAt(0).toUpperCase()}
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              flexDirection: "column",
              minHeight: laval === 3 ? "80px" : "120px",
              paddingBottom: "15px",
              marginTop: "70px",
            }}
          >
            <h3
              style={{
                color: "#000",
                fontWeight: "bolder",
                textAlign: "center",
              }}
            >
              {context[laval]?.title}
            </h3>
            <h5
              style={{
                color: "#000",
                lineHeight: "22px",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              {context[laval]?.label}
            </h5>
          </div>
          {laval !== 2 && (
            <div
              style={{
                minHeight: laval === 3 ? "80px" : "215px",
              }}
              className="subscribe-modalBody"
            >
              {laval === 0 && (
                <>
                  <Input
                    type="email"
                    name={"email"}
                    placeholder={"you@email.com"}
                    value={valueData?.email}
                    onChange={({ target: { value, name } }) => {
                      setValue({ ...valueData, [name]: value });
                      setIsValid(validateEmail(value));
                    }}
                    style={{ padding: "15px auto 15px 15px", borderColor: "#DBE0E5" }}
                    valid={isValid}
                    invalid={!isValid}
                    bsSize={"lg"}
                  />
                  <Button
                    style={{
                      marginTop: "25px",
                      padding: "15px",
                      backgroundColor: "#4680FF",
                      fontSize: "16px",
                      border: "none",
                    }}
                    onClick={() => {
                      if (isValid && !loading) {
                        setLoading(true);
                        sendOtp();
                      }
                    }}
                    disabled={!isValid}
                  >
                    {loading ? "Loading..." : "Subscribe"}
                  </Button>
                </>
              )}
              {laval === 1 && (
                <>
                  <Input
                    type="text"
                    name={"verify_otp"}
                    placeholder={"Enter Verification Code"}
                    value={valueData?.verify_otp}
                    onChange={({ target: { value, name } }) => {
                      if (/^\d{0,6}$/.test(value)) {
                        setValue({
                          ...valueData,
                          [name]: value,
                        });
                        setIsValid(/^\d{6}$/.test(value));
                      }
                    }}
                    style={{
                      padding: "15px",
                      borderColor: "#DBE0E5",
                      textAlign: "center",
                    }}
                    bsSize={"lg"}
                    autoComplete="off"
                  />
                  <Button
                    type="submit"
                    style={{
                      marginTop: "25px",
                      padding: "15px",
                      backgroundColor: "#4680FF",
                      fontSize: "16px",
                      border: "none",
                    }}
                    onClick={() => {
                      if (!!isValid && !loading) {
                        setLoading(true);
                        verifySubscribeOtp(
                          {
                            otp: valueData?.verify_otp,
                            subscribe_email: valueData?.email,
                          },
                          profileData?.link_bio_id
                        )
                          .then((res) => {
                            setLaval(2);
                            setLoading(false);
                            notifySuccess(
                              notificationMessageFunction(
                                res?.data?.data?.message
                              )
                            );
                            setCookie(
                              `subscribe_${res?.data?.data?.link_bio_id}`,
                              JSON.stringify({
                                subscribe_email:
                                  res?.data?.data?.subscribe_email,
                                subscriber_id: res?.data?.data?.subscriber_id,
                                link_bio_id: res?.data?.data?.link_bio_id,
                              })
                            );
                            setIsValid(false);
                          })
                          .catch((err) => {
                            notifyError(
                              notificationMessageFunction(
                                err?.data?.error?.message
                              )
                            );
                            if(err?.data?.error?.message === "err_0_1"){
                              setLaval(0)
                            }else{
                              setLaval(1);
                            }
                            setLoading(false);
                            setIsValid(false);
                          });
                      }
                    }}
                    disabled={!isValid}
                  >
                    {loading ? "Loading..." : " Verify"}
                  </Button>
                  <div style={{ display: "flex", margin: "10px 0" }}>
                    <h5
                      style={{
                        color: "#5B6B79",
                        lineHeight: "16px",
                        marginTop: "10px",
                        fontSize: "15px"
                      }}
                    >
                      Didn’t get a code?{" "}
                    </h5>
                    <h5
                      style={{
                        color: "#4680FF",
                        lineHeight: "16px",
                        marginTop: "10px",
                        marginLeft: "5px",
                        cursor: "pointer",
                        fontSize: "16px"
                      }}
                      onClick={() => {
                        if (!loading) {
                          setLoading(true);
                          sendOtp();
                        }
                      }}
                    >
                      Resend Code
                    </h5>
                  </div>
                </>
              )}
              {laval === 3 && (
                <>
                  <Button
                    type="submit"
                    style={{
                      marginTop: "25px",
                      padding: "15px",
                      backgroundColor: "#DC2626",
                      fontSize: "16px",
                      border: "none",
                    }}
                    onClick={() => {
                      if (hasCookies?.subscribe_email) {
                        unSubscribe(hasCookies)
                          .then((res) => {
                            setLaval(0);
                            setLoading(false);
                            notifySuccess(
                              notificationMessageFunction(
                                res?.data?.data?.message
                              )
                            );
                            setOpen(false);
                            deleteCookie(
                              `subscribe_${hasCookies?.link_bio_id}`
                            );
                            setSubscribeData(null);
                            setLaval(0);
                          })
                          .catch((err) => {
                            notifyError(
                              notificationMessageFunction(
                                err?.data?.error?.message
                              )
                            );
                          });
                      }
                    }}
                  >
                    {loading ? "Loading..." : "Unsubscribe"}
                  </Button>
                </>
              )}
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default SubscribeModal;
